





















































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { initial } from 'lodash'

@Observer
@Component({
  components: {
    'owner-section': () => import('@/modules/common/components/section/owner-section.vue'),
    'my-dao-section': () => import('@/modules/common/components/section/my-dao-section.vue'),
    'most-active-user-section': () => import('@/modules/common/components/section/most-active-user-section.vue'),
    'most-active-dao-section': () => import('@/modules/common/components/section/most-active-dao-section.vue'),
    'reward-page': () => import('@/modules/reward/pages/reward-page.vue'),
    'nft-page': () => import('@/modules/reward/pages/nft-page.vue'),
  },
})
export default class ConnectWalletStatus extends Vue {
  createPostController = createPostController
  tab = null
}
